<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProfile } from "../../../apis/user";
export default {
  mounted(){
    getProfile(this.token).then(data=>{
      this.setProfile(data)
    })
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    ...mapActions({
      setProfile: "profile/setProfile"
    })
  }
}
</script>